@import "../../assets/styles/theme";

.btn-navigation {
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  display: flex;
  flex-direction: column;
  z-index: 20;
  &:hover {
    .btn-label {
      opacity: 1;
    }
  }
  .btn-container {
    flex: 1;
    display: flex;
    min-height: 3rem;
    &.show {
      .btn {
        opacity: 0.75;
      }
    }
    &:hover {
      .btn:before {
          opacity: 1;
      }
      &.btn-prev {
        .btn:before {
          animation: btnUp 1s linear infinite;
        }
      }
      &.btn-next {
        .btn:before {
          animation: btnDown 1s linear infinite;
        }
      }
    }

    .btn {
      opacity: 0;
      flex: 1;
      position: relative;
      cursor: pointer;
      transition: all 0.5s linear;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% - 0.1rem);
        width: 0.1rem;
        background: $scroll-btn-navigation-color;
        opacity: 0.5;
        transition: all 0.5s linear;
      }
    }
  }
  .btn-label {
    margin: 1rem 0;
    font-size: $font-size-btn-scroll;
    color: $scroll-btn-navigation-color;
    opacity: 0.5;
    transition: all 0.5s linear;
  }
}
@media (max-width: $breakpoint-medium) {
  .btn-navigation {
    .btn-container {
      position: fixed;
      bottom: 0;
      display: none;
      width: 20vw;
      height: 20vw;
      background: rgba(0,0,0,0.1);
      transition: all 0.5s ease-out;

      &:hover,
      &:active {
        background: rgba(0,0,0,0.2);
      }

      &.show {
        display: flex;
      }

      &.btn-prev {
        left: 0;
        border-radius: 0 20vw 0 0;

        .btn {
          &:before {
            left: calc(50% - 4vw);
            border-left: 0.3rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }

      &.btn-next {
        right: 0;
        border-radius: 20vw 0 0;

        .btn {
          &:before {
            right: calc(50% - 4vw);
            border-right: 0.3rem solid #fff;
            transform: rotate(-45deg);
          }
        }
      }

      .btn {
        &:before  {
          content: "";
          position: absolute;
          top: calc(50% - 1vw);
          left: unset;
          width: 5vw;
          height: 5vw;
          border-bottom: 0.3rem solid #fff;
          background: none!important;
          animation: none!important;
        }
      }
    }
    .btn-label {
      display: none;
    }
  }
}

@keyframes btnUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  51% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes btnDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0;
    transform: translateY(1rem);
  }
  51% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}