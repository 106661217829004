.content-container {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 20vw 0 10vw;

  .content {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    min-height: 40rem;
    color: $text-color;
    z-index: 1;
    animation: slideIntoView 1s;

    .content-title {
      margin: 0 0 2rem;
      font-size: $font-size-slide-title;
      text-transform: uppercase;
    }

    .content-subtitle {
      max-width: 80rem;
      margin: 0 0 5rem;
      font-size: $font-size-slide-subtitle;
      text-transform: uppercase;
    }

    .slide-indicator {
      margin: 0 0 5rem;

      p {
        font-size: $font-size-slide-number;
        font-weight: 500;

        .slide-divider {
          width: 4rem;
          height: 0.1rem;
          display: inline-block;
          margin: 0 1.6rem;
          vertical-align: middle;
          background-color: $slide-number-divider;
        }
      }
    }

    .content-description {
      max-width: 65rem;
      line-height: 1.5;
      font-size: $font-size-slide-description;
      text-align: justify;

      p {
        margin-bottom: 2rem;
      }
    }
    .btn-more-container {
      .btn-more {
        position: relative;
        margin-top: 3rem;
        padding: 0 3rem 0 0;
        font-family: $font-family;
        font-size: $font-size-slide-btn;
        font-weight: 600;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        cursor: pointer;
        outline: none;
        border: none;
        background: none;
        color: $text-color;

        &:hover {
          &:before {
            animation: arrowRight 1s linear infinite;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1.8rem;
          background: url('../images/arrow.svg') no-repeat right;
          background-size: contain;
        }
      }
    }
  }
  .slide-number {
    position: absolute;
    left: 7rem;
    bottom: -8rem;
    display: flex;
    -webkit-text-stroke-width: 0.15rem;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: $slide-number-lg-color;
    color: $slide-number-lg-color;
    font-weight: 700;
    font-size: $font-size-slide-number-lg;
    line-height: 1;
    opacity: 0.15;
  }
}

@media (max-width: $breakpoint-large) {
  .content-container {
    .content {
      .content-title {
        font-size: 4rem;
      }
      .content-subtitle {
        margin-bottom: 3rem;
        font-size: 2.4rem;
      }
      .content-description {
        font-size: 1.8rem;
      }
    }
    .slide-number {
      bottom: -5rem;
      font-size: 30rem;
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .content-container {
    padding: 0 2rem;
    .content {
      margin: 0;
      min-height: unset;
      .content-title {
        font-size: 3rem;
      }
      .content-subtitle {
        font-size: 2rem;
      }
      .content-description {
        font-size: 1.6rem;
      }
    }
    .slide-number {
      display: none;
    }
  }
}

@keyframes arrowRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0;
    transform: translateX(1rem);
  }
  51% {
    opacity: 0;
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideIntoView {
  0% {
    opacity: 0.2;
    transform: translateY(-2rem) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}
