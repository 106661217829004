.form-wrapper {
  margin-bottom: 5rem;

  &.isProcessing {
    .form {
      opacity:0;

      .btn-form-container {
        justify-content: center;

        .btn-form {
          position: relative;
          width: 4rem;
          border-radius: 2rem;

          span {
            opacity: 0;
          }
        }
      }
    }
    .btn-form-loader {
      opacity: 1;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    transition: all 0.5s ease-in-out;

    * {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
      -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
      outline: none !important;
    }

    .form-control {
      flex: 1;
      display: flex;
      position: relative;
      margin: 1rem 0 4rem;

      &.up {
        label {
          left: 0.5rem;
          top: -1.25rem;
          color: $white-color;
          font-size: 1.2rem;
        }
      }

      label {
        position: absolute;
        left: 1rem;
        top: 1rem;
        transition: all 0.4s linear;
        color: rgba($white-color, 0.5);
        z-index: 1;
      }
      input, textarea {
        position: relative;
        flex: 1;
        height: 2.4rem;
        padding: 1rem;
        font-size: $font-size-m;
        border: none;
        color: $white-color;
        line-height: 1.5;
        border-bottom: 0.1rem solid $white-color;
        background: transparent;
        z-index: 2;
        -webkit-appearance: none;
        border-radius: 0;
      }

      .error {
        position: absolute;
        left: 0.25rem;
        bottom: -2.5rem;
        font-size: 1.2rem;
        color: $primary-color;
      }

      .checkbox-container {
        align-items: center;
        display: inline-flex;
        justify-content: flex-start;

        .checkbox-wrapper {
          align-items: center;
          color: $black-color;
          cursor: pointer;
          display: inline-flex;
          font-size: $font-size-m;
          font-weight: 500;
          line-height: 1.3;
          padding-left: 3rem;
          position: relative;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .checkbox-input {
            bottom: 0;
            cursor: pointer;
            height: 2.1rem;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 2.1rem;
            z-index: 20;

            &:disabled {
              cursor: not-allowed;
            }
          }

          .checkbox-input:disabled ~ .checkbox-checkmark {
            cursor: not-allowed;
            opacity: 0.6;

            &::after {
              opacity: 0.6;
            }
          }

          .checkbox-input:checked ~ .checkbox-checkmark::after {
            content: '';
          }

          .checkbox-checkmark {
            align-items: center;
            -webkit-appearance: none;
            background-color: $side-panel-background;
            border: 0.1rem solid $white-color;
            border-radius: 0.3rem;
            display: flex;
            height: 1.8rem;
            justify-content: center;
            left: 0;
            margin: 0;
            outline: none;
            overflow: hidden;
            position: absolute;
            top: 0;
            transition: all 0.3s ease-in-out;
            width: 1.8rem;

            &::after {
              background: $side-panel-background
              url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABmSURBVHgBhY8BDYAwDAQ3FExCpUwCEnCABOYAnCABCUiYBCSUX/gFsgD95NLl+8m+3hlS1RHDd0ZowFhA+AsJOMBejVB4CWYi1VhpxEdo1kt9+0XmYirl+U5fnZLe2pxxZWQVaXcnPdtrWXeNJZwAAAAASUVORK5CYII=')
              no-repeat center;
              background-size: auto;
              bottom: 0;
              height: 1.8rem;
              left: 0;
              position: absolute;
              width: 1.8rem;
            }
          }

          .checkbox-label {
            color: rgba($white-color, 0.5);
            font-size: $font-size-m;
            font-weight: 400;
            line-height: 1.5rem;

            a {
              color: $primary-color;
              text-decoration: none;
            }
          }
        }
      }
    }

    .btn-form-container {
      .btn-form {
        opacity: 0.5;

        &.valid {
          opacity: 1;
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, 0.2);
          }
        }

        &:not(.valid) {
          cursor: not-allowed;
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }

  $loader-size: 5rem;
  .btn-form-loader {
    opacity: 0;
    height: 3rem;
    width: 3rem;
    transition: all 0.5s ease-in-out;

    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: $loader-size;
      width: $loader-size;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: $loader-size;
        width: $loader-size;
        border: 0.2rem solid #FFF;
        border-radius: 50%;
        opacity: 0;
        -webkit-animation: loader-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
        animation: loader-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
      }

      &:after {
        -webkit-animation: loader-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
        animation: loader-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
      }
    }
  }
}

.recaptcha-details {
  color: rgba($white-color, 0.5);
  font-size: $font-size-s;
  font-weight: 400;
  line-height: 1.5rem;

  a {
    color: $primary-color;
    text-decoration: none;
  }
}

.btn-form-container {
  display: flex;
  .btn-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4rem;
    margin-top: 3rem;
    color: $white-color;
    border: 0.1rem solid $white-color;
    background: transparent;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    .btn-form-label {
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }
  }
}

@-webkit-keyframes loader-1 {
  0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
  100% { -webkit-transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}
@keyframes loader-1 {
  0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
  100% { transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}
@-webkit-keyframes loader-2 {
  0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
  100% { -webkit-transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}
@keyframes loader-2 {
  0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
  100% { transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}
