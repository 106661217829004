.dot-navigation {
  position: fixed;
  right: 3rem;
  top: 0;
  padding: 3rem 1rem;
  z-index: 30;
  height: 100%;
  box-sizing: border-box;

  .dot-item {
    height: calc(100% / 7);
    display: flex;
    align-items: center;
    color: $white-color;
    font-size: $font-size-dot-navigation;

    .dot-label {
      width: 8rem;
      display: flex;
      justify-content: flex-end;
      text-align: right;

      span {
        opacity: 0;
        -webkit-transition: opacity 0.6s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.6s cubic-bezier(0.3, 0.1, 0.05, 1);
        -moz-transition: opacity 0.6s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.6s cubic-bezier(0.3, 0.1, 0.05, 1);
        -o-transition: opacity 0.6s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.6s cubic-bezier(0.3, 0.1, 0.05, 1);
        -ms-transition: opacity 0.6s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.6s cubic-bezier(0.3, 0.1, 0.05, 1);
        transition: opacity 0.6s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.6s cubic-bezier(0.3, 0.1, 0.05, 1);
      }

      &:hover {
        cursor: pointer;
      }
    }

    .line-container {
      width: 0.3rem;
      height: 100%;
      display: flex;
      justify-content: center;
      margin: 0 1rem;
      position: relative;

      .line {
        width: 0.1rem;
        height: 100%;
        background: rgba($white-color, 0.1);
        position: relative;
        -webkit-transition: background-color 1s linear;
        -moz-transition: background-color 1s linear;
        -o-transition: background-color 1s linear;
        -ms-transition: background-color 1s linear;
        transition: background-color 1s linear;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 0.3rem;
        width: 0.3rem;
        background: rgba($white-color, 0.6);
        border-radius: 50%;
        -webkit-transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
        -moz-transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
        -o-transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
        -ms-transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
        transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
        z-index: 99;
      }
    }

    .dot-step {
      display: flex;

      span {
        opacity: 0.25;
        transform: translateZ(0);
        -webkit-transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
        -moz-transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
        -o-transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
        -ms-transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
        transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
      }

      &:hover {
        cursor: pointer;

        span {
          opacity: 1;
          -webkit-transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
          -moz-transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
          -o-transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
          -ms-transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
          transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1), -webkit-transform 0.4s cubic-bezier(0.3, 0.1, 0.05, 1);
          transform: translateY(-0.4rem) translateZ(0);

          &.dot-index {
            transform: translateY(0.4rem) translateZ(0);
          }
        }
      }

      &:hover + .dot-label {
        span {
          opacity: 1;
        }
      }
    }

    &.visible {
      .line-container {
        .line {
          width: 0.3rem;
          background: $primary-color;
        }

        &:after,
        &:before {
          background-color: $white-color;
        }
      }
    }

    &.active {
      .dot-label {
        span {
          opacity: 1;
        }
      }
      .dot-step {
        span {
          opacity: 1;
        }
      }

      .line-container {
        .line {
          width: 0.3rem;
          background: $primary-color;
        }

        &:after,
        &:before {
          background-color: $white-color;
        }
      }
    }

    &:last-of-type {
      .line-container {
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 0.3rem;
          width: 0.3rem;
          background: rgba($white-color, 0.6);
          border-radius: 50%;
          -webkit-transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
          -moz-transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
          -o-transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
          -ms-transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
          transition: background 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);
        }
      }
    }

    &:hover .dot-label {
      span {
        opacity: 1;
      }
    }
  }
}


@media (max-width: $breakpoint-medium) {
  .dot-navigation {
    display: none;
  }
}
