@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600&display=swap');

$font-family: 'Dosis', sans-serif;

$side-panel-width-lg: 45rem;
$side-panel-width-md: 40rem;

// basic colors:
$white-color: #fff;
$black-color: #000;

// theme colors
$primary-color: rgb(179, 0, 155);
$text-color: rgb(64, 64, 64);
$slide-number-divider: rgba(41, 22, 16, 0.2);
$side-panel-background: rgb(28, 28, 30);
$side-panel-overlay-background: #1c1c1e;
$slide-number-lg-color: rgb(58, 58, 64);
$scroll-btn-navigation-color: rgba(0,0,0,0.5);
$cookies-background: rgba(61,65,66,.85);

// font-sizes:
$font-size-xs: 1.2rem;
$font-size-s: 1.4rem;
$font-size-m: 1.6rem;
$font-size-xm: 1.8rem;
$font-size-l: 2rem;
$font-size-xl: 3.2rem;
$font-size-xxl: 5rem;
$font-size-xxxl: 40rem;

$font-size-slide-title: $font-size-xxl;
$font-size-slide-subtitle: $font-size-xl;
$font-size-slide-number: $font-size-m;
$font-size-slide-description: $font-size-l;
$font-size-slide-btn: $font-size-m;
$font-size-slide-number-lg: $font-size-xxxl;
$font-size-side-panel-header: $font-size-s;
$font-size-side-panel-title: $font-size-l;
$font-size-side-panel-content: $font-size-m;
$font-size-menu-item: $font-size-s;
$font-size-dot-navigation: $font-size-s;
$font-size-btn-scroll: $font-size-xs;

// breakpoints:
$breakpoint-xsmall: 480px; // remove
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1280px;
