.menu {
  position: absolute;
  top: 3rem;
  left: 4rem;
  height: 5rem;
  display: flex;
  align-items: center;
  // z-index: 40;

  .menu-item {
    animation-name: menuFadeIn;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    font-size: $font-size-menu-item;
    font-weight: 500;
    color: $white-color;
    letter-spacing: 0.2rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
}
@media (max-width: $breakpoint-medium) {
  .menu {
    position: fixed;
    top: 2rem;
    right: 2rem;
    left: unset;
    height: 3.5rem;
    align-items: unset;

    .menu-item {
      color: $black-color;
      animation: none;
      display: flex;
      align-items: center;
    }
  }
}
@keyframes menuFadeOut {
  0%   {
    display: none;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0;
    display: block;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@keyframes menuFadeIn {
  0%   {
    display: block;
    opacity: 1;
  }
  50% {
    opacity: 0;
    display: block;
  }
  51% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

