.side-panel-wrapper {
  position: relative;
  display: flex;
  width: $side-panel-width-lg;
  height: 100%;
  color: $white-color;
  &.open {
    .side-panel-overlay {
      opacity: 0.4;
      pointer-events: auto;
      z-index: 49;
    }
    .side-panel {
      animation-name: slidePanelIn;
      animation-duration: 1s;
      animation-fill-mode: forwards;

      .side-panel-content-wrapper {
        transform: translateY(0);
        animation-delay: 1s;
        opacity: 1;

        &.desktop {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  &.close {
    .side-panel {
      animation-name: slidePanelOut;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      .menu {
        .menu-item {
          animation-name: menuFadeOut;
          animation-duration: 2s;
          animation-fill-mode: forwards;
        }
      }
      .side-panel-content-wrapper {
        transform: translateY(8rem);
        opacity: 0;
      }
    }
  }
  .side-panel {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 3rem 4rem;
    background-color: $side-panel-background;
    transition: 0.5s all ease;
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 999;
    .side-panel-content-wrapper {
      width: 100%;
      transform: translateY(4rem);
      transition: transform 1s cubic-bezier(.19, 1, .22, 1) .8s, opacity 1s cubic-bezier(.19, 1, .22, 1) .8s;
      .side-panel-header-container {
        height: 5rem;
        margin-bottom: 2rem;
        border-bottom: 0.1rem solid $white-color;
        .side-panel-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 -1.5rem;
          .title {
            margin: 0;
            padding: 0 1.5rem;
            font-size: $font-size-side-panel-header;
            font-weight: 500;
            line-height: 1.7rem;
            text-transform: uppercase;
            position: relative;
          }
          .btn-close {
            position: relative;
            height: 4rem;
            width: 4rem;
            cursor: pointer;
            background: url('../images/close.svg') no-repeat center;
            background-size: 1.1rem;
            outline: none;
            border: none;
            opacity: 1;
            transition: all 0.5s linear;
            &:hover {
              opacity: 0.5;
              &:before {
                opacity: 1;
              }
            }
            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: rgba(255,255,255,0.05);
              border-radius: 50%;
              opacity: 0;
            }
          }
        }
      }
      .side-panel-content {
        font-size: $font-size-side-panel-content;
        line-height: 2.4rem;
        font-weight: 400;
        position: relative;
        height: 100%;
        overflow: hidden;

        .side-panel-details {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow-y: auto;

          h2 {
            margin: 4rem 0 2.5rem;
            font-size: $font-size-side-panel-title;
            font-weight: 400;
            line-height: 2rem;
            opacity: .5;
            text-transform: uppercase;
          }

          p {
            margin-bottom: 1.2rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .success-info {
            animation-name: FadeIn;
            animation-duration: 1s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
  .side-panel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $side-panel-overlay-background;
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s cubic-bezier(.25, 1, .25, 1) 0s;
    z-index: 99;
  }
}
@media (max-width: $breakpoint-large) {
  .side-panel-wrapper {
    width: $side-panel-width-md;
    .side-panel {
      padding: 3rem 2.5rem;
    }
  }
}
@media (max-width: $breakpoint-medium) {
  .side-panel-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 100vh;
    width: unset;
    transition: all 0.25s ease-out;
    z-index: 40;
    &.open {
      top: 0;
      .side-panel {
        animation: none;
      }
    }
    &.close {
      .side-panel {
        animation: none;
        .side-panel-content-wrapper {
          transform: none;
          opacity: 1;
        }
      }
    }
    .side-panel {
      .menu {
        display: none;
      }
      .side-panel-content-wrapper {
        margin-bottom: 8rem;
        transform: none;
        transition: none;
      }
    }
  }
}
@media (max-width: $breakpoint-xsmall) {
  .side-panel-wrapper {
    &.open {
      .side-panel {
        overflow-y: visible;
      }
    }
  }
}

@keyframes slidePanelIn {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    z-index: 30;
  }
  50% {
    -webkit-transform: translateX(20rem);
    transform: translateX(20rem);
    z-index: 30;
  }
  51% {
    -webkit-transform: translateX(20rem);
    transform: translateX(20rem);
    z-index: 50;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    z-index: 50;
  }
}
@keyframes slidePanelOut {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    z-index: 50;
  }
  50% {
    -webkit-transform: translateX(20rem);
    transform: translateX(20rem);
    z-index: 50;
  }
  51% {
    -webkit-transform: translateX(20rem);
    transform: translateX(20rem);
    z-index: 30;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    z-index: 30;
  }
}
@keyframes FadeIn {
  0%   {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
