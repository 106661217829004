@import './theme.scss';

* {
  -webkit-backface-visibility:  hidden;
  -webkit-tap-highlight-color:  transparent;
  -webkit-border-radius: 0;
}

html {
  font-size: 62.5%;
  font-family: $font-family;
}

body {
  padding: 0;
  margin: 0;
  height: 100vh;
  display: flex;
  overflow: hidden;

  #root {
    display: flex;
    flex: 1;
  }
}

p {
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
