.view {
  flex: 1;
  display: flex;
  height: 100vh;
  overflow: hidden;
  .view-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
  .slide {
    flex: 1;
    display: flex;
    scroll-behavior: smooth;
    .main-content-wrapper {
      flex: 1;
      position: relative;
      display: flex;
      .logo {
        position: absolute;
        right: 4rem;
        top: 3rem;
        width: 15rem;
        height: 5rem;
        background: url('../../assets/images/logo.png') no-repeat center;
        background-size: contain;
        z-index: 99;
        &:hover {
          cursor: pointer;
        }
      }
      .image-container {
        position: absolute;
        top: calc(50% - 16vw);
        right: -16vw;
        width: 30vw;
        height: 30vw;
        display: flex;
        padding: 3vw;
        z-index: 40;
        border: 0.2rem solid rgba(255, 255, 255, 0.1);
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        animation-name: slideRight;
        animation-duration: 1s;
        transition: background-image 1s ease-in;
        -moz-transition: background-image 1s ease-in;
        -web-kit-transition: background-image 1s ease-in;
        &.image-slideOut {
          animation-name: slideLeft;
          animation-duration: 1s;
        }
        .image {
          flex: 1;
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
          img {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: $breakpoint-medium) {
  .view {
    margin-top: 8rem;
    .slide {
      overflow: auto;
      display: block;
      .main-content-wrapper {
        flex-direction: column;
        .logo {
          position: fixed;
          right: unset;
          left: 2rem;
          top: 2rem;
          width: 8rem;
          height: 3.5rem;
          z-index: 1;
        }
        .image-container {
          top: unset;
          right: unset;
          position: relative;
          width: 100%;
          height: unset;
          margin-bottom: 8rem;
          border: 0;
          padding: 0;
          z-index: 20;
          .image {
            background: none!important;
            padding: 0 4rem 8rem 4rem;
            img {
              margin: 2rem 0;
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }
}
@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-12rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-12rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(4.333);
  }
}
